<template>
    <BreadCrumb :breadcrumbs="breadcrumbs" />
    <Alert :class="alertClass" v-show="alertContent" @closeAlert="alertContent = ''">{{alertContent}}</Alert>
    <Modal v-show="showModal" @closeModal="showModal = false" @confirmModal="confirmModal"/>
    <Table :tableBody="tableBody" :tableHeader="tableHeader">
      <div class="mb-3 row">
        <select v-model="claimed" class="form-select ms-3 me-2" style="width: 220px !important" @change="getList">
          <option value="1">Claimed</option>
          <option value="0">Not Claimed</option>
        </select>
        <select v-model="size" class="form-select col-2" style="width: 80px !important" @change="getList">
          <option v-for="(item, key) in pageSize" :key="key" :value="item">{{item}}</option>
        </select>
      </div>
    </Table>
</template>

<script>
import axios from 'axios'

function getDetails(item) {
  return '<span class="fa fa-user me-1"></span>' 
    + item.account.replace('<', '').replace('>', '') + '<br><span class="fa fa-search me-1"></span>'
    + (item.orderId ? item.orderId + '<br><span class="fa fa-calendar me-1"></span>' : '')
    + (new Date(item.date).toLocaleString()) + '<br><span class="fa fa-shopping-cart me-1"></span>'
    + item.itemName.substring(0,70) + (item.itemName.length > 70 ? '...' : '') + '<br>'
    + (item.itemModel ? '<span class="fa fa-barcode me-1"></span>' + item.itemModel : '')
    + (item.updatedDate && item.updatedDate.time > 0 ? '<br><span class="fa fa-calendar me-1"></span>' + new Date(item.updatedDate.time * 1000).toLocaleString() : '')
}

function getTracking(item) {
  return "<a target='_blank' href='" + (item.trackingUrl ? item.trackingUrl : "https://www.bing.com/search?q=" + item.trackingNumber) + "'>" + item.trackingNumber + "</a>"
    + "<a target='_blank' href='" + "https://www.bing.com/search?q=" + item.trackingNumber + "'>" + "<span class='ms-2 fa fa-external-link'></span>" + "</a>";
}

export default {
  name: 'Order',
  mounted() {
    this.getList();
  },
  data() {
    return {
      tableHeader: ["Claim", "Details", "Tracking", "Action"],
      tableBody: [],
      claimed: 0,
      pageSize: [100, 500, 1000, 2000],
      size: 100,
      breadcrumbs: [{ name: "Order" }],
      showModal: false,
      currentId: "",
      alertContent: "",
      alertClass: "alert-danger"
    }
  },
  methods: {
    openModal(id) {
      this.showModal = true;
      this.currentId = id;
    },
    confirmModal() {
      axios.post("/order/batch", {data: this.currentId}).then(() => {
          this.getList();
      }).catch(error => {
          this.alertContent = error.response ? error.response.data : error;
          this.alertClass = "alert-danger";
      })
      this.showModal = false;
    },
    getList() {
       axios.get("/order?size=" + this.size + "&sort=date,desc&claimed=" + this.claimed).then(response => {
          var openModal = this.openModal;
          this.tableBody = response.data.map(item => {
            return {
              claim: item.claimed ? "Claimed" : { type: "button", click: function() { openModal(item.trackingNumber) }, name: "Claim" },
              details: getDetails(item),
              tracking: getTracking(item),
              action: { type: "link", link: "/order/edit?id=" + item.id, class: "btn btn-primary btn-sm fa fa-pencil" }
            }
          })
      }).catch(error => {
          this.alertContent = error.response ? error.response.data : error;
          this.alertClass = "alert-danger";
      })
    }
  }
}
</script>
